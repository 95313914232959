import * as React from 'react';
import { Snackbar, Typography } from '@mui/material';
import { useState } from 'react';
import Button from '@mui/material/Button';
import { Container } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import axios from 'axios';
import { useSelector,} from 'react-redux';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Popover, List, ListItem, ListItemText, Divider } from '@mui/material';
import { keyframes } from '@mui/system';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';

import CloseDialog from '../dialogs/CloseDialog';
import HelpDialog from '../dialogs/HelpDialog';
import ShareDialog from '../dialogs/ShareDialog';
import ReportModal from '../modals/ReportModal';


export default function Header(props) {

    //const { apiUrl,chatbot, cl_key, setIsClosed } = props;
    
    // Redux 상태 가져오기
    const chatState = useSelector((state) => state.chatState);

    const {apiUrl, cl_key, chatbot} = chatState;
    
    const c_color = chatbot.c_color;
    const c_name = chatbot.c_name;
    const c_subtitle = chatbot.c_subtitle;
    const c_key = chatbot.c_key;

    const [isReported,setIsReported] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [helpDialOpen, setHelpDialOpen] = useState(false);
    const [shareDialOpen, setShareDialOpen] = useState(false);

    
    //도움말 열기 닫기
    const handleHelpDialOpen = ()=>{
        setHelpDialOpen(true);
        handleClose();
    }

    const handleHelpDialClose = () => {
        setHelpDialOpen(false);
    }

    //모달 열기 / 닫기
    const handleModalOpen = () => {
        setModalOpen(true);
        handleClose();
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    //공유하기 다이얼로그 열기 / 닫기
    const handleShareDialOpen =() =>{
        setShareDialOpen(true);
        handleClose();
    }

    const handleShareDialClose =() =>{
        setShareDialOpen(false);
    }


    const handleReportSubmit = (selectedReason) => {

        if(!isReported){
            const post = {
                c_key : c_key,
                cl_key : cl_key,
                r_reason :selectedReason
            }
            // console.log(post);
            axios.post(apiUrl+"/ajax/reportChatbot_axios",post)
                .then((response)=>{
                    if (response.data.resCd === 200) {
                        console.log(response.data.data);
                        setIsReported(true);
                        handleSnackClick("error",
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <ReportProblemIcon fontSize="small" />
                                <span>신고처리 되었습니다!</span>
                            </div>
                        );
                    } else {
                        console.log(response.data);
                        handleSnackClick(response.data.resMsg);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                })
                .finally(function () {
                    handleModalClose();
                });
        }else{
            handleSnackClick("warning",
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <ReportGmailerrorredIcon fontSize="small" />
                    <span>이미 신고처리 되었어요!</span>
                </div>
            );
        }
        handleModalClose();
    };
    
    //리스트 모달 관련 상태값
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    // 부모에서 Snackbar 열기/닫기 상태를 관리
    const [snackState, setSnackState] = useState({
        open: false,
        vertical: 'center',
        horizontal: 'center',
    });

    const [snackMessage, setSnackMessage] = useState("");

    // snackClick을 통해 Snackbar를 열기 위한 함수
    const handleSnackClick = (variant,message) => {
        setSnackMessage(message);
        setSnackState({ ...snackState, open: true });
        setTimeout(function(){
            handleSnackClose();
        },2000)
    };

    // snackClose를 통해 Snackbar를 닫기 위한 함수
    const handleSnackClose = () => {
        setSnackMessage("");
        setSnackState({ ...snackState, open: false });
    };
    
    const [closeDialOpen, setCloseDialOpen] = React.useState(false);

    const handleDialOpen = () => {
        setCloseDialOpen(true);
    };
  
    const handleDialClose = () => {
        setCloseDialOpen(false);
    };

    

    // Ticker 애니메이션 정의
    const tickerAnimation = keyframes`
        0% {
          transform: translateX(100%);
        }
        100% {
          transform: translateX(-100%);
        }
    `;

    return (
        <div 
            sx={{
            
            }}
            >
            <Container id="appBar" color='white' maxWidth={false}  sx={{ 
                                display: 'flex', 
                                flexDirection: 'row', 
                                alignItems: 'center', 
                                justifyContent: 'space-between', 
                                p: 1, 
                                color: c_color, 
                                backgroundColor: "#fff" ,
                                maxWidth: "400px", // 최대 너비 제한
                                width: "100%", // 부모 요소에 맞추기
                                border:'1px solid #EAE7EC',
                                position: 'fixed', // 위치를 고정
                                top: 0, // 화면 하단에 배치
                                left: 0, // 왼쪽에 맞춤
                                right: 0, // 오른쪽에 맞춤
                                zIndex: 999, // 다른 요소들보다 위에 오도록 z-index 설정
                                boxSizing: 'border-box'

                                //boxShadow: "0px 2px 5px rgba(120, 120, 120, 0.5)", // 아래쪽에만 그림자
                }}
            >
                <Button sx={{color:'#6c757d', minWidth: '36px', padding: '4px'}}
                    onClick={handleDialOpen}
                > <KeyboardArrowLeftIcon/> </Button>

                <Container sx={{ 
                        textAlign:'left',
                        display: 'flex', 
                        flexDirection: 'column',
                        position: 'relative', // 위치 기준을 relative로 설정
                        overflow: 'hidden',   // 컨테이너 내에서만 텍스트 스크롤
                    }}>
                    <Typography sx={{ fontWeight:'bold',margin:'0'}}>{c_name}</Typography>
                    <Typography sx={{ 
                                    position: 'relative',   // 컨테이너에 고정
                                    top : "0px",
                                    margin: '0',
                                    color: '#6c757d',
                                    fontSize: '13px',
                                    whiteSpace: 'nowrap',  // 한 줄로 유지
                                    overflow: 'hidden',    // 넘치는 부분 숨기기
                                    display: 'inline-block',
                                    animation: `${tickerAnimation} 10s linear infinite`, // 10초에 한 번씩 반복
                                }}
                                    >{c_subtitle}</Typography>
                </Container>

                <Button sx={{color:'#6c757d', minWidth: '36px', padding: '4px'}} onClick={handleClick}> <MoreVertIcon/> </Button>

                {/* Popover for the List */}
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <List sx={{ width: '200px' }}>
                        <ListItem onClick={handleModalOpen}> {/* 'button' 속성만 사용 */}
                            <ListItemText primary="신고하기" />
                        </ListItem>
                        <Divider />
                        <ListItem onClick={handleShareDialOpen}> {/* 'button' 속성만 사용 */}
                            <ListItemText primary="공유하기" />
                        </ListItem>
                        <Divider />
                        <ListItem onClick={handleHelpDialOpen}> {/* 'button' 속성만 사용 */}
                            <ListItemText primary="도움말" />
                        </ListItem>
                    </List>
                </Popover>
            </Container>

            {/* 신고하기 모달 */}
            <ReportModal 
                modalOpen={modalOpen} 
                onClose={handleModalClose} 
                onSubmit={handleReportSubmit}
            />

            <Snackbar
                open={snackState.open}
                vertical={snackState.vertical}
                horizontal={snackState.horizontal}
                onClose={handleSnackClose}
                message={snackMessage} // 메시지를 'message' prop으로 전달
                // autoHideDuration={2000} // 2초 후 자동으로 닫히도록 설정
                sx={{ 
                    position: 'fixed',
                    '& .MuiSnackbarContent-root': {
                        backgroundColor: '#53000DFF',
                        color: '#fff',
                        minWidth: '200px',
                        justifyContent: 'center',
                    }
                 }}
            />

            {/* 닫기 물어보는 다이얼로그 */}
            <CloseDialog 
                open={closeDialOpen}
                onClose={handleDialClose}
                onOpen={handleDialOpen}
            />

            {/* 도움말 다이얼로드 */}
            <HelpDialog
                open={helpDialOpen}
                onClose={handleHelpDialClose}
                onOpen={handleHelpDialOpen}
            />

            <ShareDialog
                open={shareDialOpen}
                onClose={handleShareDialClose}
                onOpen={handleShareDialOpen}
            />
        </div>
    );
}
