import { configureStore, createSlice } from '@reduxjs/toolkit'

let chatState = createSlice(
    {
        name : 'chatState',
        initialState:{
            apiUrl : 'https://simchat.aimor.site',
            cl_key : "",
            cs_questions : [],
            keywords : [],
            notices :[],
            chatbot : null,
            c_color : '',
            isActive : '',
            isMsging : false,
            isLoaded : false,
            isClosed : false,
            chatBubbles : []
        },
        reducers: {
            setCl_key: (state, action) => {
                state.cl_key = action.payload;
            },
            setCs_questions: (state, action) => {
                state.cs_questions = action.payload;
            },
            setKeywords : (state, action) =>{
                state.keywords = action.payload;
            },
            setNotices : (state, action) =>{
                state.notices = action.payload;
            },
            setChatbot : (state, action) =>{
                state.chatbot = action.payload;
            },
            setC_color : (state, action) =>{
                state.c_color = action.payload;
            },
            setIsActive : (state, action) =>{
                state.isActive = action.payload;
            },
            setIsMsging : (state, action) =>{
                state.isMsging = action.payload;
            },
            setIsLoaded : (state, action) =>{
                state.isLoaded = action.payload;
            },
            setIsClosed : (state, action) =>{
                state.isClosed = action.payload;
            },
            setChatbubbles : (state, action) =>{
                state.chatBubbles = action.payload;
            },

        }
    }
)

export const { setCl_key, setCs_questions, setKeywords,setNotices, setChatbot,setC_color,setIsActive,setIsMsging,setIsLoaded,setIsClosed,setChatbubbles } = chatState.actions;

const store = configureStore({
    reducer: {
        chatState: chatState.reducer,
    },
    devTools: process.env.NODE_ENV !== 'production',
});

export default store;