import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector,useDispatch  } from 'react-redux';
import { setIsClosed } from '../store/store';


export default function CloseDialog(props) {

    // const chatState = useSelector((state) => state.chatState);
    // const {updateIsClosed} = chatState;

  const {open , onClose } = props;

  const dispatch = useDispatch();

  const updateIsClosed = (isClosed) => {
    dispatch(setIsClosed(isClosed));
  }

  //챗봇 종료하기
  const chatClose= ()=>{
    onClose();
    updateIsClosed(true);
  }

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"알림"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            대화를 종료하고 창을 닫을까요?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button  color="primary" onClick={onClose}>취소</Button>
          <Button  color="error" onClick={chatClose} autoFocus>
            종료
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}