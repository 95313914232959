import React, { useState } from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogActions,
    RadioGroup, FormControlLabel, Button,Typography, Container,Radio,Box
} from '@mui/material';


function ReportModal({ modalOpen, onClose, onSubmit }) {

    const [selectedReason, setSelectedReason] = useState("기타 문제");

    const handleReasonChange = (event) => {
        setSelectedReason(event.target.value);
    };

    const handleSubmit = () => {
        onSubmit(selectedReason);
        onClose();
    };

    return (
        <Dialog
            open={modalOpen}
            onClose={onClose}
            fullWidth
            maxWidth="sm"
            aria-labelledby="report-modal-title"
            sx={{}}
        >
            <DialogTitle id="report-modal-title" sx={{  m: 0, p: 1, textAlign:'center'  }}>
                신고하기
            </DialogTitle>

            <DialogContent dividers sx={{ p: 0.2 }}>
                <Container maxWidth="sm" sx={{ p:0 }}>
                    <Box sx={{ p: 2 }}>
                        
                        <Typography sx={{ textAlign: 'center', mb: 1, fontSize: '12px',color:'#3F3F3F',paddingBottom: '5px' }}>
                            문제가 되는 이유를 선택해주세요.
                        </Typography>

                        <RadioGroup
                            value={selectedReason}
                            onChange={handleReasonChange}
                            sx={{ }}
                        >
                            {["폭력적 또는 선정적인 텍스트나 이미지", "불쾌한 텍스트 또는 이미지", "인종 또는 장애에 대한 차별, 모욕", "과도한 홍보성 또는 스팸", "기타 문제"].map((reason, index) => (
                                <FormControlLabel
                                    key={index}
                                    value={reason}
                                    control={<Radio />} // Radio 컴포넌트는 여기서 직접 사용됩니다.
                                    label={<Typography fontSize="13px">{reason}</Typography>}
                                    sx={{ py: 1, alignItems: 'center' }}
                                />
                            ))}
                        </RadioGroup>
                    </Box>
                </Container>
            </DialogContent>

            <DialogActions sx={{ justifyContent: 'space-between', p: 1.5 }}>
                <Button
                    color="dark"
                    size="small"
                    onClick={onClose}
                    sx={{ textTransform: 'none' }}
                >
                    취소
                </Button>
                <Button
                    variant="contained"
                    color="error"
                    size="small"
                    onClick={handleSubmit}
                    sx={{ textTransform: 'none' }}
                >
                    신고
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ReportModal;
