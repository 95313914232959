import * as React from 'react';
import {useState,useEffect,useRef} from 'react';
import { Backdrop, Box, Button, Container, Divider, Fade, TextField, Typography } from '@mui/material';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import SendIcon from '@mui/icons-material/Send';
import LaunchIcon from '@mui/icons-material/Launch';
import { alpha } from '@mui/system';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { setChatbubbles, setIsMsging } from '../store/store';


export default function BottomBar() {

    //Redux에서 변수 상태 가져오기
    const chatState = useSelector(state => state.chatState);
    const {apiUrl, chatbot, cl_key, chatBubbles, keywords, isMsging} = chatState;

    //Dispatch 정의
    const dispatch = useDispatch();
    const updateChatbubbles = (newChatBubbles) => dispatch(setChatbubbles(newChatBubbles));
    const updateIsMsging = (newIsMsging) => dispatch(setIsMsging(newIsMsging));

    //메시지 상태
    const [message, setMessage] = useState("");

    //추천질문 Fade 상태
    const [showRecommendations, setShowRecommendations] = useState(false);

    const recommendationsRef = useRef(null);
    const buttonRef = useRef(null); // 버튼 요소 참조 추가


    // 배경 클릭 시 창 닫기
    useEffect(() => {
        const handleClickOutside = (event) => {
            // recommendationsRef, buttonRef 모두 event target을 포함하지 않을 때만 닫기
            if (
                recommendationsRef.current &&
                buttonRef.current &&
                !recommendationsRef.current.contains(event.target) &&
                !buttonRef.current.contains(event.target)
            ){
                setShowRecommendations(false);
            }
        };

        // 클릭 이벤트 리스너 추가
        document.addEventListener('mousedown', handleClickOutside);

        // 컴포넌트 언마운트 시 이벤트 리스너 제거
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    //메시지 전송 함수
    function sendMsg(user_input){
        console.log(user_input);

        var userMsgObj = {
            isUser: true,
            message: user_input
        }

        //사용자 채팅 버블
        const newChatBubbles = [...chatBubbles, userMsgObj];
        updateChatbubbles(newChatBubbles);
        updateIsMsging(true);

        console.log("send Message level : 1 > ", chatBubbles);

        // axios를 사용하여 요청하고 결과 받기
        axios.post(apiUrl+'/ajax/chat_answer_axios', {
            user_input: user_input,
            cl_key: cl_key,
            c_key: chatbot.c_key
        })
        .then(function (response) {
            //console.log(response.data);
            if (response.data.resCd === 200) {
                //console.log(response.data.data);
                const botMsgObj = {
                    isUser: false,
                    message: response.data.bot_msg
                }
                if(response.data.simQust) botMsgObj.simQust = response.data.simQust;
                updateChatbubbles([...newChatBubbles, botMsgObj]);
            } else {
                // var errorMsg = "챗봇 엔진이 과도하게 바쁜 상태입니다. 페이지를 새로고침 후 다시 시도해주세요!";
                console.log(response.data);
                const botMsgObj = {
                    isUser: false,
                    message: response.data.resMsg
                }
                updateChatbubbles([...newChatBubbles, botMsgObj]);
            }
            console.log("send Message level : 2 > ", chatBubbles);
        })
        .catch(function (error) {
            console.log(error);
            var botMsgObj = {
                isUser: false,
                message: "챗봇 엔진이 응답시간을 초과했습니다. 잠시 후 다시 시도해주세요!"
            }
            updateChatbubbles([...newChatBubbles, botMsgObj]);
        })
        .finally(function () {
            updateIsMsging(false);
        });
    }

  const handleSend = () => {
    sendMsg(message); // TextField의 값을 sendMsg 함수에 전달
    setMessage('');   // 메시지 전송 후 TextField 초기화
  };

    // +버튼 클릭 핸들러
    const handleAddClick = (event) => {
        //event.stopPropagation(); // 클릭 이벤트 전파 방지
        setShowRecommendations((prev) => !prev); // 상태 토글
    };

    const setMessageAndSend = (keyword) =>{
        sendMsg(keyword); // TextField의 값을 sendMsg 함수에 전달
        setShowRecommendations(false);
    }

  const c_color = chatbot.c_color;

  return (
    <div>
        <Container id='bottomNavigation' disableGutters maxWidth={false}  sx={{
                    // width:'99.5vw',
                    maxWidth:'400px',
                    position: 'fixed', // 위치를 고정
                    bottom: 0, // 화면 하단에 배치
                    left: 0, // 왼쪽에 맞춤
                    right: 0, // 오른쪽에 맞춤
                    zIndex: 1000, // 다른 요소들보다 위에 오도록 z-index 설정
                    border:'1px solid #EAE7EC',
                    margin:'0 auto',
                    display:'flex',
                    paddingTop: '6px',
                    paddingBottom: '6px',
                    backgroundColor:"#fff",
                    boxSizing: 'border-box'
                }}>
            <Button sx={{ color:c_color }} 
                    ref={buttonRef} // 버튼 요소에 참조 추가
                    onClick={handleAddClick}>
                <LaunchIcon  sx={{
                    fontSize:'30px',
                    rotate : 'revert'
                }}/>
            </Button>
            <TextField type='text' 
                sx={{ 
                    width:'80%',
                    '& .MuiInputLabel-root.Mui-focused': {
                        color: alpha(c_color,0.8), // 포커스 시 레이블 색상
                    },
                    '& .MuiOutlinedInput-root.Mui-focused': {
                        '& fieldset': {
                            // borderColor: alpha(c_color,0.8), // 기본 테두리 색상
                        },
                        '&:hover fieldset': {
                            borderColor: alpha(c_color,0.3), // hover 시 테두리 색상
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: alpha(c_color,0.8), // 포커스 시 테두리 색상
                        },
                        
                    },
                 }} 
                onChange={(e) => setMessage(e.target.value)} 
                label="질문하기"
                value={message}
                disabled = {isMsging ? true : false}
                onKeyDown={(e) => {
                    if (e.key === 'Enter' && !isMsging && message !== '') {
                        handleSend();
                    }
                }}
                />    
            <Button sx={{ color:c_color }} 
                onClick={handleSend}
                disabled = {isMsging || message === '' ? true : false}
                >
                <SendIcon sx={{fontSize:'30px'}}/>
            </Button>
        </Container>

        <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: 999,
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                }}
                open={showRecommendations}
                onClick={() => setShowRecommendations(false)}
        />

        <Fade in={showRecommendations} timeout={{ enter: 500, exit: 500 }} // Fade In: 1초, Fade Out: 0.5초
        >
            <Box 
                className="recommendBox"
                ref={recommendationsRef}
                sx={{ 
                    width:'100%',
                    zIndex: 1000,  // Box의 z-index를 Backdrop보다 높게 설정
                    position:'fixed',
                    bottom : '70px',
                    left: '50%', // 화면 중앙으로 이동
                    transform: 'translateX(-50%)', // 중앙 정렬을 위해 -50% 이동
                    backgroundColor : alpha(c_color,0.7),
                    margin : '0 auto',
                    border:1, borderColor: c_color,
                    overflow:'hidden',
                    borderRadius:'25px',
                    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.3)', // 뿌연 그림자 추가
                    
                    // overflowX:'scroll'
            }}>
                <Typography variant="h6" sx={{ backgroundColor:c_color, color:"#fff", padding:"5px",fontSize:'13px',textAlign:'center' }}>
                    <TouchAppIcon sx={{fontSize:'20px'}}/>
                    추천 질문을 눌러주세요!
                </Typography>
                <Divider/>
                <Container sx={{ 
                    // width:'auto',
                    overflowX : 'scroll', 
                    // padding:'5px',

                    // 스크롤바 스타일 커스터마이징
                    '&::-webkit-scrollbar': {
                        width: '1px', // 스크롤바의 너비를 매우 얇게 설정
                        height: '8px', // 스크롤바의 너비를 매우 얇게 설정
                    },
                    '&::-webkit-scrollbar-track': {
                        background: 'transparent', // 트랙을 투명하게
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: c_color, // 스크롤바 색상
                        borderRadius: '7px', // 스크롤바를 둥글게
                        border: '1px solid transparent', // 스크롤바의 경계가 보이지 않게
                    },
                }}>
                    <Box variant="outlined" aria-label="Basic button group" sx={{ 
                        width:'auto',
                        display: 'flex',
                        flexWrap:'wrap',
                        gap: 2, // 버튼 간 간격 설정
                        backgroundColor:'transparent',
                        padding:'5px 8px 10px 8px',
                    }}>
                        {keywords.map((keyword,index)=>{
                            return (
                                <Button key={index} 
                                    sx={{ 
                                    backgroundColor:"#fff",
                                    color : c_color ,
                                    fontSize:'12px',
                                    padding:'5px',
                                    minWidth: 'fit-content', // 최소 너비를 텍스트에 맞게 설정
                                    whiteSpace: 'nowrap', // 줄바꿈을 막기 위해 설정
                                }}
                                onClick={()=>setMessageAndSend(keyword)}

                                >{keyword}</Button>
                            )
                        })}
                    </Box>
                </Container>
            </Box>
        </Fade>
    </div>
  );
}